import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React,{ RefObject } from 'react'
import {
  getStorageData,
  setStorageData,
  removeStorageData
} from "../../../framework/src/Utilities";
import { centerCrop, makeAspectCrop,Crop } from 'react-image-crop'
export interface ICreateUserResponseAttributes {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  gender: string | null;
  profile_photo: string | null;
  cover_photo: string | null;
}

export interface IMeta {
  token: string;
  refresh_token: string;
}

export interface ICreateUserResponse {
  data: {
    id: string;
    type: string;
    attributes: ICreateUserResponseAttributes;
  };
  meta: IMeta;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  checked?: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  otpInput: string;
  authToken: string;
  isClick: boolean;
  otpError: string;
  password: string;
  checked: boolean;
  userData: {
    name: string;
    phoneNumber: string;
    dateOfBirth: string;
    checked: boolean;
    email: string;
    token: string;
  };
  isShow: boolean;
  passwordError: string;
  checkboxError: string;
  apiError: string;
  accountCreationApiError: string;
  changeScreen: number;
  userName: string;
  userNames: string[];
  profilePicture: Blob | null | boolean;
  status: string;
  profileToken: string;
  showUsers: boolean;
  selectPictureError: string;
  uploadPicApiError: string;
  userNamesApiError: string;
  isPasswordButtonClickable: boolean;
  isOtpButtonClickable: boolean;
  suggestedUserNames: string[];
  userImage: string;
  userMobileNumber: string;
  openModal: boolean;
  privacyModel: boolean;
  termsAndConditionsData: {
    id: number;
    created_at: string;
    updated_at: string;
    title: string;
    description: string[];
  }[];
  termsAndConditionsError: string;
  otpPageLoading: boolean;
  profile: boolean;
  isXs:boolean;
  snackBarOpen:boolean,
  alertMessage:{
    message:string,
  };
  loggedUserLoading:boolean
  open:boolean,
  title:string,
  isImage:boolean,
  crop:Crop,
  croppedFile:Blob|null,
  croppedImage:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  submitButtonColor: string = configJSON.submitButtonColor;
  otpAuthApiCallId: string = "";
  btnTxtSubmitOtp: string = "";
  placeHolderOtp: string = "";
  labelInfo: string = "";
  accountCreationApiCallId: string = "";
  otpApiCallId: string = "";
  createAccountApiCallId: string = "";
  suggestionApiCallId: string = "";
  uploadPictureApiCallId: string = "";
  termsAndConditionsApiCallId: string = "";
  resetOtpCallId: string = "";
  updateUserNameApiCallId: string = "";
  apiGetDataCallId: string = "";
  timeout:ReturnType<typeof setTimeout>|null=null;
  resendOtpId:string="";
  skipUsernameApi:string='';
  skipProfileApi:string='';
  imageRef: RefObject<HTMLImageElement> = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.OTPScreenMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      croppedImage:'',
      croppedFile:null,
      loggedUserLoading:false,
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      otpInput: "",
      authToken: "",
      isClick: false,
      otpError: "",
      password: "",
      checked: false,
      userData: {
        name: "",
        phoneNumber: "",
        dateOfBirth: "",
        checked: false,
        email: "",
        token: "",
      },
      isShow: false,
      passwordError: "",
      checkboxError: "",
      apiError: "",
      accountCreationApiError: "",
      changeScreen: 0,
      userName: "",
      userNames: [],
      profilePicture: null,
      status: "",
      profileToken: "",
      showUsers: false,
      selectPictureError: "",
      userNamesApiError: "",
      uploadPicApiError: "",
      isPasswordButtonClickable: true,
      isOtpButtonClickable: true,
      suggestedUserNames: [],
      userImage: "",
      userMobileNumber: "",
      openModal: false,
      privacyModel: false,
      termsAndConditionsData: [],
      termsAndConditionsError: "",
      otpPageLoading: false,
      profile: false,
      isXs:false,
      snackBarOpen:false,
      alertMessage:{
        message:'',
      },
      open:false,
      title:"",
      isImage:false,
      crop:{  unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50}
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

    this.getNavigation(message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getSucess(message);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool,
      });
    }

    this.otpApi(message);
    this.createAccountApi(message);
    this.suggestionApi(message);
    this.uploadPictureApi(message);
    this.resendOtpRes(message);
    this.updateUserName(message);
    this.getLoggedUser(message)
    this.getResendOtp(message)
    this.skipUsernameResponse(message)
    this.skipProfileResponse(message)
    // Customizable Area End
  }

  // Customizable Area Start

  getSucess = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson &&
      (responseJson.messages || (responseJson.meta && responseJson.meta.token))
    ) {
      if (responseJson.meta && responseJson.meta.token) {
        this.setState({
          otpAuthToken: responseJson.meta.token,
        });
      }

      if (this.state.isFromForgotPassword) {
        const message: Message = new Message(
          getName(MessageEnum.NavigationNewPasswordMessage)
        );
        message.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.otpAuthToken
        );

        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );

        this.send(message);
      } else {
        const message: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
        );

        message.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.otpAuthToken
        );

        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );

        this.send(message);
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse != null) {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  getNavigation = async(message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = await message.getData(getName(MessageEnum.SessionResponseData));
      this.setRedirection(data?.active)
      const OTPScreen = await message.getData(getName(MessageEnum.OTPScreenMessage));
      console.log("nav message",OTPScreen,data);
      if(OTPScreen!==undefined){
        this.setState({changeScreen: OTPScreen },this.getUserData);
      }
      
      if(data?.screen && data?.response){
        this.setState({changeScreen:data.screen||3,userName:data.response.data.attributes.user_name})
        this.storeUserToken(data.response.meta.token);
        this.handleMultiUser(data.response);
        const userData={name:data.response.data.attributes.user_name,userName:data.response.data.attributes.user_name,dateOfBirth:data.response.data.attributes.date_of_birth,token:data.response.meta.token,phoneNumber:data.response.data.attributes.phone_number,email:data.response.data.attributes.email}
        setStorageData('userInfo',JSON.stringify(userData))
        setStorageData('userAccount','username')
        
      }
      if(data!==undefined && !data.active){
      this.setState({ profile: data.profile, changeScreen: data.screen || 3 },this.getUserData);
      }
      if(OTPScreen!==undefined){
        this.setState({changeScreen: OTPScreen },this.getUserData);
        }
      }
  };

  setRedirection=(screen:string)=>{
    if(screen==='user'){
      this.setState({changeScreen:2},()=>setStorageData("screen", 'username'))
    }
    else if(screen==='profile'){
      this.setState({changeScreen:3},()=>setStorageData("screen", 'profile')) 
    }
  }

  getUserData = async () => {
    let token = await getStorageData("authToken");
    this.setState({
      loggedUserLoading:false
    })
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.categoriesApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  skipUsernameResponse=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.skipUsernameApi) {
        if (responseJson && !responseJson.errors) {
          this.setState(
            (prevState) => ({
              changeScreen: prevState.changeScreen + 1,  
            }))
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          if (responseJson.errors) {
            this.setState({ accountCreationApiError: responseJson.errors });
          }
        }
      }
    }

  }
  
  skipProfileResponse=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.skipProfileApi) {
        if (responseJson && !responseJson.errors) {
          const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          message.addData(getName(MessageEnum.NavigationTargetMessage), "Categories");
          this.send(message);
          removeStorageData('selection')
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          if (responseJson.errors) {
            this.setState({ accountCreationApiError: responseJson.errors});
          }
        }
      }
    }

    
  }

  otpApi = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.otpApiCallId) {
        this.handleOtpResponse(responseJson);
      }
    }
  };

  getLoggedUser=(message: Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiGetDataCallId) {
        if(!responseJson?.errors){
          this.setState({
          userName:responseJson.data.attributes.user_name,
          loggedUserLoading:false
          })
          if(responseJson.data.attributes.profile_photo!==null){
            this.setState({
              userImage: responseJson.data.attributes.profile_photo
             
            })
          }
        } 
      }
    }
  }
  getResendOtp=(message: Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.resendOtpId) {
        if(responseJson){
        this.updateTokenInLocalStorage(responseJson?.meta?.token)
        
        } 
      }
    }
  }

  

  handleOtpResponse = (responseJson: { errors: Array<string>; data: {} }) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ apiError: "" });
      setStorageData('screen','password')
      let params = window.location.href;
      if (params.includes("ForgotPasswordOTP")) {
        this.props.navigation.navigate("NewPassword");
      } else {
        this.navigateToPassword();
      }
    } else {
      this.setState({ apiError: responseJson.errors[0] });
    }
  };

  createAccountApi = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.createAccountApiCallId) {
        if (responseJson && !responseJson.errors) {
          this.showUsers(responseJson.meta.token);
          this.storeUserToken(responseJson.meta.token);
          this.handleMultiUser(responseJson);
          this.setState(
            (prevState) => ({
              profileToken: responseJson.meta.token,
              changeScreen: prevState.changeScreen + 1,
              accountCreationApiError: "",
            }),
            () => {
              const accountUser = "username";
              setStorageData("userAccount", accountUser);
            }
          );
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          if (responseJson.errors) {
            this.setState({ accountCreationApiError: responseJson.errors });
          }
        }
      }
    }
  };

  conditionShort =(condition:boolean,trueStatement:string,falseStatement:string)=>{
    return condition ?trueStatement:falseStatement
  }

  handleMultiUser = async (responseJson: ICreateUserResponse) => {
    let getMultiUser: Array<{
      refresh_token: string | undefined;
      user_id: string;
      token: string | undefined;
      profile_photo: string;
      username: string;
      fullName: string;
    }> = [];
    const payload = {
      refresh_token: responseJson?.meta && responseJson?.meta?.refresh_token,
      user_id: responseJson.data.id,
      token: responseJson?.meta && responseJson?.meta?.token,
      profile_photo: responseJson.data.attributes.profile_photo as string,
      username: responseJson.data.attributes.user_name,
      fullName: responseJson.data.attributes.full_name,
    };
    setStorageData("selectedUser", JSON.stringify(payload));
    const userData = await getStorageData("multi_user", true);

    getMultiUser = userData ?? [];
    if (getMultiUser.length > 0) {
      const findUser = getMultiUser.find(
        (item) => item.user_id === payload.user_id
      );
      if (findUser) {
        const multi_user = getMultiUser.map((item) => {
          if (item.user_id === payload.user_id) {
            return {...item,username:payload.username,profile_photo:payload.username};
          } else {
            return item;
          }
        });
        setStorageData("multi_user", JSON.stringify(multi_user));
      } else {
        getMultiUser.push(payload);
        setStorageData("multi_user", JSON.stringify(getMultiUser));
      }
    } else {
      getMultiUser.push(payload);
      setStorageData("multi_user", JSON.stringify(getMultiUser));
    }
  };

  suggestionApi = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.suggestionApiCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            userNames: responseJson.usernames,
            suggestedUserNames: responseJson.usernames.slice(0, 2),
          });
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          this.setState({ userNamesApiError: responseJson.errors });
        }
      }
    }
  };

  updateUserName = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.updateUserNameApiCallId) {
        if (responseJson && !responseJson.errors) {
          const accountUser = "profile";
          this.handleMultiUser(responseJson);
          setStorageData("userAccount", accountUser);
          setStorageData("screen", "profile");
          this.setState(
            {
              userNamesApiError: "",
              apiError: "",
            },
            () => this.gotoUploadPicture()
          );
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          this.setState({ userNamesApiError: responseJson?.errors[0] });
        }
      }
    }
  };

  uploadPictureApi = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.uploadPictureApiCallId) {
        if (responseJson && !responseJson.errors) {
            const accountUser = "profile";
            this.handleMultiUser(responseJson);
            setStorageData("userAccount", accountUser);
            setStorageData("screen",'profile');
            removeStorageData('selection')
            this.navigateToCategories();
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          this.setState({ uploadPicApiError: responseJson.errors[0] });
        }
      }
    }
  };

  navigateToLandingPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), "/");
    this.send(message);
  };

  navigateToCategories = () => {
    this.skipUserProfileScreen()
  };
  
  skipUserProfileScreen=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'select_category')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.skipProfileApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showUsers = (token: string) => {
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.suggestionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suggestionApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.suggestionApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  storeUserToken = async (token: string) => {
    await setStorageData("authToken", token);
    await setStorageData("screen", 'username');
  };

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const sendData = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: sendData,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  async componentDidMount() {
    this.navigationOnLoading()
    const IsLoggedinFromApple= window.localStorage.getItem("IsLoggedinFromApple")
    if (IsLoggedinFromApple === "true") {
      this.setState({ changeScreen: 3 });
    }
    let params = window.location.href;
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (!params.includes("ForgotPasswordOTP")) {
      this.getPhoneNumber();
    }
    const userInfo:any=window.localStorage.getItem("userInfo")
    const userInfoObj= JSON.parse(userInfo)
    if (userInfoObj.email) {
      this.setState({title:"Use Phone number Instead"})
      window.localStorage.setItem("isFromOtp","PhoneNumber")
    }
    if (userInfoObj.phoneNumber) {
      this.setState({title:"Use Email ID Instead"})
      window.localStorage.setItem("isFromOtp","Email")
    }
  }

  async componentWillUnmount() {
    window.localStorage.setItem("IsLoggedinFromApple","false")
    window.removeEventListener('resize', this.handleResize);
   
  }
   handleResize = () => {
    if(window.innerWidth<=600){
    this.setState({ isXs: true });
    }else{
      this.setState({ isXs: false });
    }
  };

  handleMediaQueryChange = (event: MediaQueryListEvent) => {
    this.setState({ isXs: event.matches });
  };

  getPhoneNumber = async () => {
    const userData = await getStorageData("userInfo");
    const userInfo = userData!==null && JSON.parse(userData);
    this.setState({
      userData: userInfo,
      userMobileNumber: userInfo.phoneNumber
    });
  };

  navigateToPassword = () => {
    this.setState((prevState) => ({
      apiError: "",
      accountCreationApiError: "",
      changeScreen: prevState.changeScreen + 1,
    }));
  };

  verifyOtp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const otpRegex = configJSON.otpRegex;
    const userData = await getStorageData("userInfo");
    const userInfo = JSON.parse(userData);
    if (this.state.otpInput !== "") {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      const sendData = {
        otp_code: this.state.otpInput,
        token: userInfo.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.otpApiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(sendData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  createUserAccount = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({
      apiError: "",
      userNamesApiError: "",
      uploadPicApiError: "",
    });
    const passwordRegex = configJSON.passwordRegex;
    const attrs =
      this.state.userData.email === ""
        ? {
            full_name: this.state.userData.name,
            password: this.state.password,
            full_phone_number: this.state.userData.phoneNumber,
            date_of_birth: this.state.userData.dateOfBirth,
            is_terms_and_conditions_accepted: this.state.checked,
            is_early_adopter: true,
          }
        : {
            full_name: this.state.userData.name,
            password: this.state.password,
            email: this.state.userData.email,
            date_of_birth: this.state.userData.dateOfBirth,
            is_terms_and_conditions_accepted: this.state.checked,
            is_early_adopter: true,
          };

    if (this.state.password === "") {
      this.setState({ passwordError: "" });
    } else if (!passwordRegex.test(this.state.password)) {
      this.setState({ passwordError: configJSON.enterValidPassword });
    } else {
      this.setState({ passwordError: "" });
    }
    if (
      this.state.password !== "" &&
      passwordRegex.test(this.state.password) &&
      this.state.checked === false
    ) {
      this.setState({ checkboxError: configJSON.termsAndConditions });
    } else {
      this.setState({ checkboxError: "" });
    }

    if (
      this.state.password !== "" &&
      passwordRegex.test(this.state.password) &&
      this.state.checked === true
    ) {
      const sendData = {
        type:
          this.state.userData.email === "" ? "sms_account" : "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: sendData,
      };

      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountCreationApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleChangeOtp = (event: { target: { value: string } }) => {
    const otpRegex = configJSON.otpRegex;
    this.setState({ otpInput: event.target.value,apiError:'' }, () => {
      this.handleEnableDisableOtpButton();
    });
  };

  handleEnableDisableOtpButton = () => {
    if (
      this.state.otpInput !== "" 
    ) {
      this.setState({ isOtpButtonClickable: false });
    } else {
      this.setState({ isOtpButtonClickable: true });
    }
  };

  handlePasswordChange = (event: { target: { value: string } }) => {
    const passwordRegex = configJSON.passwordRegex;
    this.setState({ password: event.target.value }, () => {
      this.handleEnableDisablePasswordButton();
    });
    this.timeout!==null && clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.target.value === "") {
        this.setState({ passwordError: "" });
      } else if (!passwordRegex.test(event.target.value)) {
        this.setState({ passwordError: configJSON.enterValidPassword });
      } else {
        this.setState({ passwordError: "" });
      }
    },800)  
    
    if (event.target.value.length > 0 && this.state.passwordError) {
      this.setState({passwordError:''})
    } 
  };

  handleEnableDisablePasswordButton = () => {
    if (
      this.state.password !== "" &&
      configJSON.passwordRegex.test(this.state.password) &&
      this.state.checked === true
    ) {
      this.setState({ isPasswordButtonClickable: false });
    } else {
      this.setState({ isPasswordButtonClickable: true });
    }
  };

  handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked }, () => {
      this.handleEnableDisablePasswordButton();
    });
    if (event.target.checked === false) {
      this.setState({ checkboxError: configJSON.termsAndConditions });
    } else {
      this.setState({ checkboxError: "" });
    }
  };

  showPassword = () => {
    this.setState({ isShow: !this.state.isShow });
  };
  handleChangeUserName = (event: { target: { value: string } }) => {
    this.setState({
      userName: event.target.value,
      userNamesApiError: "",
    });
  };

  handleChangePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({croppedImage:''})
    const file = event.target.files?.[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      this.setState({ profilePicture: file, selectPictureError: "" }, () => {
        this.setState({ userImage: URL.createObjectURL(file),
          isImage:true 
        });
      });
    } else {
      this.setState({ selectPictureError: configJSON.picError });
    }
  };

  updateUserNameAPI = async () => {
    const formData = new FormData();
    if (this.state.userName) {
      formData.append("user_name", this.state.userName);
      formData.append("signup_state",'choose_profile')
    }
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserNameApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  uploadProfilePicture = async () => {
    const formData = new FormData();
    this.state.profilePicture &&
      formData.append("profile_photo", this.state.croppedFile!==null?this.state.croppedFile:this.state.profilePicture  as Blob);
    if (this.state.userName) {
      formData.append("user_name", this.state.userName);
    }
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadPictureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  gotoUploadPicture = () => {
    this.setState((prevState) => ({
      apiError: "",
      userNamesApiError: "",
    }),()=>this.skipUsernameScreen());
  };

  skipUsernameScreen=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'choose_profile')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.skipUsernameApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  gotoUserName = () => {
    this.setState((prevState) => ({
      apiError: "",
      changeScreen: prevState.changeScreen - 1,
    }),()=>{
      setStorageData('screen','username')
      this.showPreviousUsers()
    });
  };

  showPreviousUsers=async()=>{
    const authToken=await getStorageData('authToken')
    this.showUsers(authToken)
  }

  addUserName = (name: string) => {
    this.setState({ userName: name });
  };

  showMoreSuggestions = () => {
    this.setState({ showUsers: true });
  };

  closeuploadPicture = () => {
    this.setState({ profilePicture: null });
  };

  navigateToEmail = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    this.send(message);
  };

  navigateTopassword = () => {
    this.setState((prevState) => ({
      apiError: "",
      accountCreationApiError: "",
      userNamesApiError: "",
      uploadPicApiError: "",
      changeScreen: prevState.changeScreen - 1,
    }));
  };

  navigateToOtp = () => {
    this.setState((prevState) => ({
      accountCreationApiError: "",
      userNamesApiError: "",
      uploadPicApiError: "",
      changeScreen: prevState.changeScreen - 1,
    }));
  };

  navigateToTermsAndConditions = () => {
    this.setState({ openModal: true });
  };

  navigateToPrivacyPolicy =() => {
    this.setState({ privacyModel: true });
  }

  handleClose = () => {
    this.setState({ openModal: false, privacyModel: false });
  };
  
  handleTermsAndConditions = () => {
    this.setState({
      openModal: false,
      privacyModel:false,
    });
  };

  handleResendOtp = async () => {
    this.setState({
      otpPageLoading: true,
    });
    const headers = {
      "content-type": configJSON.apiVerifyOtpContentType,
    };

    let userInfo = await getStorageData("userInfo");

    let httpBody = userInfo && JSON.parse(userInfo);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/send_otp"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody?.userData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseSnackbar=()=>{
    this.setState({
       snackBarOpen:false,alertMessage:{ message:''},
    })
  }

  resendOtpRes = async (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.resetOtpCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({ apiError: "", otpPageLoading: false, snackBarOpen:true, alertMessage:{
            message:'Success: Otp has sent',
          }});
          let userData = await getStorageData("userInfo");
          userData = { ...userData, token: responseJson?.meta?.token || "" };
          await setStorageData("userInfo", JSON.stringify(userData));
        } else {
          this.setState({
            apiError: responseJson.errors[0],
            otpPageLoading: false,
          });
        }
      }
    }
  };

  navigationOnLoading=async()=>{
    const screen=await getStorageData('screen')
    const authToken=await getStorageData('authToken')
    if(screen==='username'){
      this.setState({changeScreen:2},()=>this.showUsers(authToken))
    }else if(screen==='profile'){
      this.setState({changeScreen:3})
    }else if(screen==='password'){
      this.setState({changeScreen:1})
    }else{
      this.setState({changeScreen:0})
    }
  }

  handleClickOpen=()=>{
    this.setState({open:true})
  }
  handleClickClose=()=>{
    this.setState({open:false})
  }

  resendOtp = async () => {
    const resendOtp = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const userInfo:any=window.localStorage.getItem("userInfo")
    const userInfoObj= JSON.parse(userInfo)
    const {email,phoneNumber} = userInfoObj
    const attributes: any = {};

    if (email) {
      attributes.email = email;
      this.setState({title:"Use Phone number Instead"})
    }
    if (phoneNumber) {
      attributes.full_phone_number = phoneNumber;
      this.setState({title:"Use Email ID Instead"})
    }

    const body = {
      data: {
        attributes
      }
    };


    this.resendOtpId = resendOtp.messageId;
    resendOtp.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpEndpoint
    );
    resendOtp.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    resendOtp.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    resendOtp.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(resendOtp.id, resendOtp);
     this.handleClickClose()
  }
  handleRedirection=()=>{
    this.props.navigation.navigate("EmailAccountRegistration")
  }

   updateTokenInLocalStorage = (newToken:any) => {
    // Retrieve the current userInfo from local storage
    const userInfoString = window.localStorage.getItem('userInfo');
  
    if (userInfoString) {
      // Parse the string to an object
      const userInfo = JSON.parse(userInfoString);
  
      // Update the token field
      userInfo.token = newToken;
  
      // Save the updated object back to local storage
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } else {
      console.error('No userInfo found in local storage.');
    }
  };
  
  
  onImageLoad=(e:{currentTarget:{width:number,height:number}})=>{
    const{width,height}=e.currentTarget
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 50,
        },
        1,
        width,
        height
      ),
      width,
      height
    )

    this.setState({crop:crop})
  }

  getCroppedImg = () => {
    if(this.imageRef.current!==null){
      
    
    const canvas = document.createElement('canvas');
    const scaleX =this.imageRef.current.naturalWidth / this.imageRef.current.width;
    
    const scaleY = this.imageRef.current.naturalHeight / this.imageRef.current.height;

    canvas.width = this.state.crop.width;
    canvas.height = this.state.crop.height;

    const ctx = canvas.getContext('2d');
    if(ctx!==null){
      ctx.drawImage(
        this.imageRef.current,
        this.state.crop.x * scaleX,
        this.state.crop.y * scaleY,
        this.state.crop.width * scaleX,
        this.state.crop.height * scaleY,
        0,
        0,
        this.state.crop.width,
        this.state.crop.height
      );
    }
    

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  }
  };

  sendCroppedImage = async (blob:Blob|unknown) => {   
   const croppedImageUrl = URL.createObjectURL(blob as Blob);
   this.setState({croppedImage:croppedImageUrl})
   this.setState({croppedFile:blob as Blob})
  }
  handleCropComplete=async()=>{
    const croppedImageBlob = await this.getCroppedImg();
    this.sendCroppedImage(croppedImageBlob)
  }

  closeDragImage=()=>{
    this.setState({isImage:false})
  } 

  updateCroppedImage=(c:Crop)=>{
    this.setState({crop:c})
  }

  // Customizable Area End
}
