import React, { useEffect, useRef, useState } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng";
import { makeStyles } from "@material-ui/core/styles";
import ChatInLivestream, { ChatInLivestreamRef } from "./ChatInLivestream";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import CloseIcon from "@material-ui/icons/Close";
import CallEndIcon from "@material-ui/icons/CallEnd";
import GroupIcon from "@material-ui/icons/Group";
import { IconButton } from "@material-ui/core";
import { IUserProfile } from "../../blocks/customisableuserprofiles/src/ProfileController.web";

const client: IAgoraRTCClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

interface LivestreamProps {
  onGoHome: () => void;
  loggedUser: IUserProfile;
  channelName: string;
  livestreamToken: string;
  uid: number;
  roomId: string | null;
  userId: string;
  chatToken: string;
  isHost: boolean;
  ownerId: number | null;
}

let audioTrack: IMicrophoneAudioTrack | undefined;
let videoTrack: ICameraVideoTrack | undefined;

const Livestream: React.FC<LivestreamProps> = ({
  onGoHome,
  channelName,
  livestreamToken,
  uid,
  chatToken,
  roomId,
  userId,
  isHost,
  loggedUser,
  ownerId,
}) => {
  const appid = "1780315edacd414390427571df32d22e";
  const classes = useStyles();
  const { createMicrophoneAudioTrack, createCameraVideoTrack } = AgoraRTC;
  const [isAudioOn, setIsAudioOn] = useState<boolean>(false);
  const [isVideoOn, setIsVideoOn] = useState<boolean>(false);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [numberOfAudience, setNumberOfAudience] = useState<number>(0);

  useEffect(() => {
    audioTrack = undefined;
    videoTrack = undefined;
    joinChannel();
  }, []);

  const chatRoomRef = useRef<ChatInLivestreamRef>(null);

  const handleLogoutChat = () => {
    if (chatRoomRef.current) {
      chatRoomRef.current.logoutChat();
    }
  };

  const toggleCamera = async (flag: boolean) => {
    setIsVideoOn(flag);
    if (videoTrack) {
      return videoTrack.setEnabled(flag);
    }
    videoTrack = await createCameraVideoTrack();
    videoTrack.play("camera-video");
  };

  const toggleMicrophone = async (flag: boolean) => {
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }
    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };

  const publishVideo = async () => {
    try {
      await toggleCamera(true);
      if (videoTrack) {
        await client.publish(videoTrack);
      }
    } catch (error) {
      console.log(`Publish video fail with error: ${error}`);
    }
  };

  const publishAudio = async () => {
    try {
      await toggleMicrophone(true);
      if (audioTrack) {
        await client.publish(audioTrack);
      }
    } catch (error) {
      console.log(`Publish audio fail with error: ${error}`);
    }
  };

  const onUserPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio"
  ) => {
    try {
      if (mediaType === "video") {
        const remoteTrack = await client.subscribe(user, mediaType);
        remoteTrack.play("remote-video");
        if (!isHost) {
          setIsVideoOn(true);
        }
      }
      if (mediaType === "audio") {
        const remoteTrack = await client.subscribe(user, mediaType);
        remoteTrack.play();
      }
    } catch (error) {
      console.log(`User publish fail with error: ${error}`);
    }
  };

  const onUserUnpublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio"
  ) => {
    try {
      if (mediaType === "video") {
        await client.unsubscribe(user, mediaType);
        if (!isHost) {
          setIsVideoOn(false);
        }
      }
      if (mediaType === "audio") {
        await client.unsubscribe(user, mediaType);
      }
    } catch (error) {
      console.log(`User unpublish fail with error: ${error}`);
    }
  };

  const leaveChannel = async () => {
    try {
      setIsJoined(false);
      handleLogoutChat();
      if (isHost) {
        await unpublishAudio();
        await unpublishVideo();
      }
      await client.leave();
      onGoHome();
    } catch (error) {
      console.log(`Leave livestream fail with error: ${error}`);
    }
  };

  const unpublishAudio = async () => {
    try {
      await toggleMicrophone(false);
      await client.unpublish(audioTrack);
    } catch (error) {
      console.log(`Unpublish audio fail with error: ${error}`);
    }
  };

  const unpublishVideo = async () => {
    try {
      await toggleCamera(false);
      await client.unpublish(videoTrack);
    } catch (error) {
      console.log(`Unpublish video fail with error: ${error}`);
    }
  };

  const onUserLeft = async (user: IAgoraRTCRemoteUser) => {
    if (user.uid === ownerId) {
      leaveChannel();
    }
  };

  const joinChannel = async () => {
    try {
      if (isJoined) {
        await leaveChannel();
      }
      if (channelName && livestreamToken && uid) {
        client.on("user-published", onUserPublish);
        client.on("user-unpublished", onUserUnpublish);
        client.on("user-left", onUserLeft);
        await client.join(appid, channelName, livestreamToken, uid);
        console.log("Join livestream successfully!");
        setIsJoined(true);
        if (isHost) {
          publishAudio();
          publishVideo();
        }
      }
    } catch (error) {
      console.log(`Join livestream fail with error: ${error}`);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftSideContainer}>
        <div className={classes.videoWrapper}>
          <video
            id={isHost ? "camera-video" : "remote-video"}
            className={classes.cameraVideo}
            hidden={isVideoOn ? false : true}
          />
          <div
            className={classes.avatarWrapper}
            style={{
              display: isVideoOn ? "none" : "block",
            }}
          />
        </div>
        {isHost ? (
          <div className={classes.actionBtnWrapper}>
            <IconButton
              onClick={isAudioOn ? unpublishAudio : publishAudio}
              style={{ background: isAudioOn ? "#333537" : "#fadedc" }}
            >
              {isAudioOn ? (
                <MicIcon style={{ color: "#fff" }} width={32} height={32} />
              ) : (
                <MicOffIcon
                  style={{ color: "#60150f" }}
                  width={32}
                  height={32}
                />
              )}
            </IconButton>
            <IconButton
              onClick={isVideoOn ? unpublishVideo : publishVideo}
              style={{ background: isVideoOn ? "#333537" : "#fadedc" }}
            >
              {isVideoOn ? (
                <VideocamIcon
                  style={{ color: "#fff" }}
                  width={32}
                  height={32}
                />
              ) : (
                <VideocamOffIcon
                  style={{ color: "#60150f" }}
                  width={32}
                  height={32}
                />
              )}
            </IconButton>
            <IconButton
              onClick={leaveChannel}
              style={{ background: "#EF4444" }}
            >
              <CallEndIcon style={{ color: "#fff" }} width={32} height={32} />
            </IconButton>
          </div>
        ) : (
          <IconButton
            onClick={leaveChannel}
            className={classes.audienceCloseLivestream}
          >
            <CloseIcon style={{ color: "#fff" }} width={32} height={32} />
          </IconButton>
        )}
        <div className={classes.numberAudienceWrapper}>
          <GroupIcon style={{ color: "#fff" }} width={32} height={32} />
          <p className={classes.numberAudienceText}>{numberOfAudience}</p>
        </div>
      </div>
      <div className={classes.rightSideContainer}>
        <ChatInLivestream
          ref={chatRoomRef}
          roomId={roomId || "270107663204354"}
          userId={userId}
          chatToken={chatToken}
          loggedUser={loggedUser}
          setNumberOfAudience={setNumberOfAudience}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    maxWidth: "1280px",
    height: "100%",
    display: "flex",
  },
  leftSideContainer: {
    flex: 1,
    height: "100%",
    boxSizing: "border-box",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  videoWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cameraVideo: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  avatarWrapper: {
    width: "160px",
    height: "160px",
    background: "linear-gradient(-45deg, #3858E3, #BA05F7)",
    borderRadius: "50%",
  },
  actionBtnWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "24px",
    padding: "16px 0",
  },
  rightSideContainer: {
    width: "360px",
  },
  audienceCloseLivestream: {
    position: "absolute",
    top: "24px",
    left: "24px",
    background: "#333537",
  },
  numberAudienceWrapper: {
    position: "absolute",
    top: "24px",
    right: "24px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.07)",
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  numberAudienceText: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: 1.2,
  },
}));

export default Livestream;
