import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { EventSubscription } from "fbemitter";
import { EMITTER_EVENT_VARIABLES, emitterEvents, generateTokenCall, startCallSession } from "../../../components/src/utils";
import { CometChatCalls } from "@cometchat/calls-sdk-javascript";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { toast } from "react-toastify";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Recording {
  userId: string;
  roomId: string;
  sessionId: string;
  fileId: string;
  file: {
    meta: {
      resolution: {
        width: number;
        height: number;
      };
      format: string;
      duration: number;
    };
  };
  size: number;
  filePath: string;
  ratio: {
    [key: string]: number;
  };
  fileUrl: string;
  type: string;
  id: string;
}

export interface RecordingDetail {
  userId: string;
  roomId: string;
  sessionId: string;
  fileId: string;
  resolution: string;
  format: string;
  duration: number;
  size: number;
  filePath: string;
  fileUrl: string;
  type: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  recordingDetail: RecordingDetail;
  tokenSessionCall: string;
  currentCall: CometChat.Call | null;
  isCaller: boolean;
  loggedInUser: CometChat.User | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CallRecordingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subscriptionIncomingCall: EventSubscription | null = null;
  subscriptionAcceptCallSession: EventSubscription | null = null;
  subscriptionRejectCallSession: EventSubscription | null = null;
  subscriptionStartCallSession: EventSubscription | null = null;
  subscriptionEndCallSession: EventSubscription | null = null;
  subscriptionCancelCall: EventSubscription | null = null;
  subscriptionCurrentCall: EventSubscription | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      recordingDetail: {} as RecordingDetail,
      tokenSessionCall: "",
      currentCall: null,
      isCaller: false,
      loggedInUser: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    switch (message.id) {
      case getName(MessageEnum.SessionResponseMessage): {
        let token: string = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        runEngine.debugLog("TOKEN", token);
        if (token) {
          this.setState({ token });
        }
        break;
      }
      case getName(MessageEnum.NavigationPayLoadMessage): {
        try {
          const navigationPayload = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
          if (navigationPayload?.isAcceptCall) {
            const call = await CometChat.acceptCall(navigationPayload.sessionIDCall);
            const token = await generateTokenCall(call.getSessionId())
            await startCallSession(token, call);
            this.setState({tokenSessionCall: token, currentCall: call})
            emitterEvents.emit(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, true)
          } else {
            await this.startCallAudio(navigationPayload);
          }
        } catch (error) {
          console.error("Start call session failed with error: ", error)
        }
        break;
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.subscriptionAcceptCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.ACCEPT_CALL, (call: CometChat.Call, tokenSessionCall: string) => {
      this.setState({ tokenSessionCall, currentCall: call, isCaller: false })
    })
    this.subscriptionEndCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.END_CALL_SESSION, () => {
      this.setState({ tokenSessionCall: "", currentCall: null })
    })
    this.subscriptionRejectCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.REJECT_CALL, () => {
      this.handleResetCall();
    })
    this.subscriptionCurrentCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CURRENT_CALL, (call: CometChat.Call | null) => {
      if(!call) return;
      this.setState({currentCall: call})
    })
    this.subscriptionStartCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.START_CALL_SESSION, async (call: CometChat.Call) => {
      const token = await generateTokenCall(call.getSessionId());
      this.setState({ tokenSessionCall: token, isCaller: false })
      await startCallSession(token, call);
    })
    this.subscriptionIncomingCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.INCOMING_CALL, (call: CometChat.Call) => {
      this.setState({currentCall: call})
    })
    this.subscriptionCancelCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CANCEL_CALL, (call: CometChat.Call) => {
      if (call.getSessionId() !== this.state.currentCall?.getSessionId()) return;
      this.handleResetCall()
    })
    const user = await CometChatUIKit.getLoggedinUser()
    this.setState({loggedInUser: user})
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    if (this.subscriptionStartCallSession) {
      this.subscriptionStartCallSession.remove()
    }
    if (this.subscriptionEndCallSession) {
      this.subscriptionEndCallSession.remove()
    }
    if (this.subscriptionAcceptCallSession) {
      this.subscriptionAcceptCallSession.remove()
    }
    if (this.subscriptionRejectCallSession) {
      this.subscriptionRejectCallSession.remove()
    }
    if (this.subscriptionCurrentCall) {
      this.subscriptionCurrentCall.remove()
    }
    if (this.subscriptionIncomingCall) {
      this.subscriptionIncomingCall.remove()
    }
    if (this.subscriptionCancelCall) {
      this.subscriptionCancelCall.remove()
    }
    if (this.state.tokenSessionCall) {
      try {
        if (this.state.isCaller){
          // Cancel call if you're  calling status
          const status = CometChat.CALL_STATUS.CANCELLED;
          const call = await CometChat.rejectCall(this.state.currentCall?.getSessionId() ?? "", status);
          console.log("Call canceled successfully", call);
        } else {
          // End call 
          this.endCallAudio();
        }
      } catch (error) {
        console.log("Call cancelation failed with error:", error);
      }
    }

    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages)
  }
  
  startCallAudio = async ({receiverID, receiverType}: {receiverID: string, receiverType?: string}) => {
    const callType: string = CometChat.CALL_TYPE.AUDIO;
    const getReceiverType: string = receiverType || CometChat.RECEIVER_TYPE.USER;

    const call: CometChat.Call = new CometChat.Call(
      receiverID,
      callType,
      getReceiverType
    );
    try {
      await new Promise<void>((resolve) => {
        this.setState({ isCaller: true }, () => {
          resolve();
        });
      });
      const outGoingCall = await CometChat.initiateCall(call)
      const sessionIDCall = outGoingCall.getSessionId()
      emitterEvents.emit(EMITTER_EVENT_VARIABLES.IS_CALLING, true)
      console.log("Call initialization successfully with sessionId:", sessionIDCall)
      this.setState({ currentCall: outGoingCall });
    } catch (error) {
      const errorCast = error as CometChat.CometChatException;
      emitterEvents.emit(EMITTER_EVENT_VARIABLES.IS_CALLING, false)
      this.handleResetCall();
      console.log("Call initialization failed with exception:", errorCast);
      toast.error(errorCast.message)
    }
  }

  endCallAudio = () => {
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, true)
    CometChat.clearActiveCall();
    CometChatCalls.endSession();
  }

  getToken = () => {
    const sessionRequestMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(sessionRequestMessage);
  };

  handleBackChatScreen = () => {
    this.props.navigation.navigate("Chat")
  }

  handleCancelCall = async () => {
    const status = CometChat.CALL_STATUS.CANCELLED;
    try {
      const call = await CometChat.rejectCall(this.state.currentCall?.getSessionId() ?? "", status)
      console.log("Call canceled successfully", call);
      toast.success("Call cancel successfully");
    } catch (error) {
      console.log("Call cancelation failed with error:", error);
      toast.error("Call cancel fail");
    }
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.IS_CALLING, false);    
    this.handleResetCall();
  }

  handleResetCall = async () => {
    this.setState({ isCaller: false, tokenSessionCall: "", currentCall: null });
  }

  // Customizable Area End
}
