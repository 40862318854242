import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { profileAvatar, rejectCallIcon, searchDark, searchWhite } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { handleConditionFunction } from "../../../components/src/utils";
import { backArrowDark, backArrowWhite } from "../../../blocks/chat/src/assets";
import ImageRenderer from "../../../components/src/ImageRenderer.web";

// Customizable Area End

import CallRecordingController, {
  Props,
  RecordingDetail,
  configJSON,
} from "./CallRecordingController.web";
import IncomingCall from "./IncomingCall.web";
import clsx from "clsx";

// Customizable Area Start
// Customizable Area End

export default class CallRecording extends CallRecordingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainCat = () => {
    return (
      <Box className={`mainCategory`}>
        <Box className={`chatTopHeader`}>
          <Box className="titleContainer">
            <IconButton data-test-id="backBtn" onClick={this.handleBackChatScreen}>
              <img className="backIcon" src={handleConditionFunction(this.props.checked, backArrowDark, backArrowWhite)} />
            </IconButton>
            <Typography className={`chatTitle`}>Calls</Typography>
          </Box>
        </Box>
        <Box className={`searchContainer`}>
          <Input
            id="input-with-icon-adornment"
            className={`searchField`}
            placeholder="Search"
            // value={this.state.searchKeyword}
            // onChange={this.handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <img src={this.props.checked ? searchDark : searchWhite} />
              </InputAdornment>
            }
          />
        </Box>
      </Box>
    )
  }

  renderSideCat = () => {
    return (
      <Box className="contentContainer">
        {this.renderContainerCallScreen()}
        <Box id="callContainer">
        </Box>
      </Box>
    )
  }

  renderContainerCallScreen = () => {
    if (this.state.isCaller) return this.renderCallerScreen();
    return (
      !this.state.tokenSessionCall && <IncomingCall
        navigation={this.props.navigation}
        checked={this.props.checked}
        id=""
        currentCall={this.state.currentCall}
        tokenSessionCall={this.state.tokenSessionCall}
      />
    )
  }

  renderCallerScreen = () => {
    return <Box className="container_caller">
      <Box className="header_caller">
        <Typography className={`header_caller_text ${clsx(this.props.checked && "lightTheme-text")}`}>
          Waiting for other...
        </Typography>
      </Box>
      <Box className="content">
        <Box className="profile_user">
          <Box className="header_profile_user">
            <Typography className="header_profile_user_text">
              You
            </Typography>
          </Box>
          <Box className="background_profile_user">
            <img src={this.state.loggedInUser?.getAvatar()}/>
          </Box>
        </Box>
      </Box>
      <Box className="list_button">
        <IconButton data-test-id="cancelCallBtn" onClick={this.handleCancelCall}>
          <ImageRenderer src={rejectCallIcon} width={64} height={64} />
        </IconButton>
      </Box>
    </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <NavigationMenu
        navigation={this.props.navigation}
        id={""}
        checked={this.props?.checked}
      >
        <CallRecordingStyled>
          {this.renderMainCat()}
          {this.renderSideCat()}
        </CallRecordingStyled>
      </NavigationMenu>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CallRecordingStyled = styled(Box)(() => ({
  display: 'flex',
  "& .mainCategory": {
    height: '100vh',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "Manrope",
    padding: '0 15px',
    flex: 4,
    maxWidth: '420px',
    borderRight: '2px solid #222222',
    "& .chatTopHeader": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      paddingTop: '15px',
      "& .titleContainer": {
        display: "flex",
        alignItems: "center",
      },
      "& .chatTitle": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 800,
      },
    },
    "& .searchContainer": {
      border: '1px solid #6B6B6B',
      borderRadius: '10px',
      height: "48px",
      padding: '0px 15px',
      alignContent: 'center',
      '&:has(.Mui-focused)': {
        border: "1px solid #7A2BF5"
      },
      "& .searchField": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        color: "#6B6B6B",
        '&::before': {
          display: "none",
        },
        '&::after': {
          display: "none",
        },
        width: "100%",
        "& ::placeholder": {
          color: "#6B6B6B",
          opacity: 1
        }
      }
    }
  },
  "& .contentContainer": {
    display: "flex",
    flexDirection: "column",
    flex: 6,
    maxWidth: '680px',
    borderRight: '2px solid #222222',
    position: "relative",
    "& #callContainer": {
      flex: "1",
      " & .cc-bottom-buttons.cc-bottom-buttons-container": {
        background: "#96969633"
      },
      "& .bottom-buttons-other-options": {
        display: "none",
      },
      "& .cc-main-container": {
        background: "transparent",
        "& .awssld__content": {
          background: "transparent",
          "& .tile-main-container": {
            background: "transparent",
            "& .tile-list-wrapper": {
              "& .grid-box": {
                borderWidth: 2,
                borderColor: "#222222",
                "& .cc-video-container": {
                  background: "transparent",
                }
              },
              "& .show-border": {
                borderColor: "#1e79e7"
              }
            }
          }
        },
      }
    },
    "& #settingButton": {
      display: "none"
    },
    "& .container_caller": {
      flex: 20,
      maxWidth: '680px',
      padding: "20px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .header_caller": {
        marginTop: "10vh",
        "& .header_caller_text": {
          fontFamily: "Manrope",
          fontWeight: 600,
          fontSize: "16px",
          color: "#E0E0E0",
        },
        "& .header_caller_text.lightTheme-text": {
          color: "#222222",
        }
      },
      "& .content": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "& .profile_user": {
          padding: 10,
          border: "1px solid transparent",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          background: "linear-gradient(90deg, #070707 100%, #070707 100%) padding-box, linear-gradient(99.09deg, #6EE7B7 2.64%, #059669 100%) border-box",
          "& .background_profile_user": {
            width: "140px",
            height: "137px",
            backgroundImage: `url(${profileAvatar})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            "& img": {
              position: "absolute",
              top: "calc(50%)",
              left: "calc(50%)",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              width: "142px",
              height: "140px",
              borderRadius: "10px",
            }
          },
          "& .header_profile_user": {
            "& .header_profile_user_text": {
              backgroundImage: "linear-gradient(99.09deg, #6EE7B7 2.64%, #059669 100%)",
              color: "transparent",
              backgroundClip: "text",
              fontFamily: "Manrope",
              fontWeight: 700,
              fontSize: "14px"
            }
          }
        }
      },
      "& .list_button": {
        position: "absolute",
        bottom: 0,
        width: "calc(100% - 4px )",
        background: "#96969633",
        display: "flex",
        justifyContent: "space-around",
      }
    }
  }
}))
// Customizable Area End
