import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { verifyIcon,verifyOrange,verifyYellow,ownerShipIcon } from "./assets"
import {removeStorageData, getStorageData } from "../../../framework/src/Utilities";
import React from "react"
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checked: boolean | undefined;
  theme:string;
  userToken:string;
  errorMsg:string;
  followers:{id:number,name:string,username:string,subscription:string|null,ownership:boolean,description:string,followers:string ,isfollowing:boolean,profilePhoto:string|null}[],
  currentPage:number;
  totalPages:number;
  loggedUser:{id:number,name:string,username:string,subscription:string|null,ownership:boolean,description:string,followers:string ,twitterName:string|null, twitterFollowers:null|number ,isfollowing:boolean,profilePhoto:string|null},
  isLoading:boolean;
  followMessage:string;
  unFollowMessage:string;
  totalRecords:number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  followersListCallId:string=''
  loggedInUserCallId:string=''
  followCallId:string=''
  unFallowCallId:string=''
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      checked: true,
      theme:'lightTheme',
      userToken:'',
      errorMsg:'',
      followers:[],
      currentPage:1,
      totalPages:10,
      loggedUser:{id:0,name:'', username:"", subscription:null, ownership:false,description:'',followers:'', twitterName:null, twitterFollowers:null,isfollowing:false,profilePhoto:''},
      isLoading:true,
      followMessage:'',
      unFollowMessage:'',
      totalRecords:0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.errors&&responseJson?.errors[0] =="Invalid token"){
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }else {
    this.loggedInUserApi(from, message)
    this.getUsersListApi(from, message);
    this.followUserApi(from, message);
    this.unfollowUserApi(from, message)
    runEngine.debugLog("Message Recived", message);
   }}
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getUserToken()
  } 
 
  getUserToken=async()=>{
    const userToken=await getStorageData('authToken')
    removeStorageData('userAccount')
    this.setState({userToken:userToken},()=>{
      this.getLoggedInUser()
      this.getListOfUsers()
    })

  } 

  getLoggedInUser=()=>{
    const headers = {
      token:this.state.userToken,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loggedInUserCallId = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInUserEndPoint
      
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);

  }

  getListOfUsers=()=>{
    const pages=this.state.currentPage===1?9:10
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.state.userToken,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followersListCallId = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followersListEndPoint}?page=${this.state.currentPage}&per_page=${pages}`
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);

  }

  getUsersListApi=(from: string, message: Message)=>{
    if (
      this.followersListCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading:false})
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        const pages= Math.ceil(apiResponse.pagination_details.total_records/5)
        const records=apiResponse.pagination_details.total_records
        const result=apiResponse.data.map((each:{attributes:{id:number,full_name:string,user_name:string, bio:string|null,is_following:boolean,profile_photo:string|null,followers_count:number,user_subscription:string|null,user_ownership:boolean}})=>
        ({
          id:each.attributes.id,
          name:each.attributes.full_name,
          description:each.attributes.bio,
          followers:'0',
          isfollowing:each.attributes.is_following,
          username:each.attributes.user_name,
          subscription:each.attributes.user_subscription,
          profilePhoto:each.attributes.profile_photo,
          ownership:each.attributes.user_ownership
        }))
        this.setState({followers:result,totalPages:pages,totalRecords:records})  
      }
    }
  }


  renderVerifyIcon= (val:{user_subscription:string|null}) =>{
  
      switch (val.user_subscription) {
        case 'I am an individual':
          return <img src={verifyIcon} alt="" height={18} width={18}/>;
        case 'I am a business':
          return <img src={verifyYellow} alt="" height={18} width={18} />;
        case 'I am a news organization':
          return <img src={verifyOrange} alt="" height={18} width={18} />;   
        case null:
          return ""               
     } 
  }

  renderOwnershipIcon= (val:{user_ownership:boolean}) =>{
    if(val.user_ownership ){
          return <img src={ownerShipIcon} alt="" height={18} width={18}/>;         
      }       
  }


  loggedInUserApi=(from: string, message: Message)=>{
    if (
      this.loggedInUserCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.setState({isLoading:false})

      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        const result= {
          id:apiResponse.data.attributes.id,
          name:apiResponse.data.attributes.full_name,
          description:apiResponse.data.attributes.bio,
          username:apiResponse.data.attributes.user_name,
          subscription: apiResponse.data.attributes.user_subscription,
          ownership:apiResponse.data.attributes.user_ownership,
          followers:'0',
          twitterName:apiResponse.data.attributes.twitter_username,
          twitterFollowers:apiResponse.data.attributes.twitter_followers_count,
          isfollowing:apiResponse.data.attributes.is_following,profilePhoto:apiResponse.data.attributes.profile_photo}
        this.setState({loggedUser:result})  
      }
    }

  }

  handleFollowUser=(userId:number)=>{
    let result = this.state.followers.map((item)=>
      item.id === userId
          ? { ...item, isfollowing: !item.isfollowing }
          : item       
    )    
    this.setState({followers:result})
   
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.userToken,
    };
    let httpBody = {
      account_id:userId
    }
    
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followApiEndpoint
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
  }

  handleFollowingUser=(userId:number)=>{
    let result = this.state.followers.map((item)=>
      item.id === userId
          ? { ...item, isfollowing: !item.isfollowing }
          : item       
    )    
    this.setState({followers:result})

    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.userToken,
    };
    let httpBody = {
      account_id:userId
    }
    
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unFallowCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unfollowUserEndpoint
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
     

  }

  followUserApi=(from: string, message: Message)=>{
    if (
      this.followCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        this.setState({followMessage:apiResponse.messages[0]}) 
      }
    }

  }

  unfollowUserApi=(from: string, message: Message)=>{
    if (
      this.unFallowCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        this.setState({unFollowMessage:apiResponse.messages[0]}) 
      }
    }

  }

  formatUsernameText=(text:string)=>{
    return (text&&text.length>15)?`${text.slice(0,13)}...`:text
  }

  formateDescription=(description:string)=>{
    return (description&&description.length>50)?`${description.slice(0,47)}...`:description
  }

  handlePageChange=(event: React.ChangeEvent<unknown>, value: number)=>{
    this.setState({currentPage:value},()=>{
      this.getListOfUsers()
    })
  }

  handleLogout = async () => {
    await removeStorageData("cometChatToken");
    await removeStorageData("authToken");
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    this.send(message);
    await CometChatUIKit.logout();
  }

  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToProfile=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfiles"
    );
    this.send(message); 
  }
  // Customizable Area End
}
